import { Box, BoxProps, Flex, Heading, Text } from '@chakra-ui/react'
import { useTranslation } from 'next-i18next'
import NextLink from 'next/link'
import { FC } from 'react'

import type { FrontSearchProjectType } from '@miimosa/common/types'
import { Image, getProjectStatus, Icon } from '@miimosa/design-system'
import { Button } from '@miimosa/design-system/components'

import { CloseCard, ProjectLocation } from './components'

interface Props extends BoxProps {
  href?: string
  project: FrontSearchProjectType
  showButton?: boolean
  onClose?: () => void
  isBordered?: boolean
  withOwner?: boolean
  showVideo?: boolean
  voteCollected?: number
  hideVideo?: boolean
  hideQR?: boolean
}

const ProjectCard: FC<Props> = ({
  project,
  showVideo = false,
  onClose,
  voteCollected,
  isBordered = false,
  hideVideo,
  hideQR,
  ...props
}) => {
  const { teasing, state, imageFileName, slug, title, place, shortDescription } = project
  const { t } = useTranslation('common')
  const { isSoon } = getProjectStatus({
    status: state,
    teasing,
  })

  const Card = () => (
    <Flex
      direction="column"
      borderRadius="4px"
      alignItems="stretch"
      overflow="hidden"
      borderWidth="1px"
      borderColor="transparent"
      sx={{
        _hover: {
          borderWidth: '1px',
          borderColor: 'primary.DEFAULT',
          '.cta': {
            visibility: 'visible',
          },
        },
      }}
      width={{ base: '100%', md: '345px' }}
      {...props}
    >
      {onClose && <CloseCard close={onClose} position="absolute" top="12px" right="12px" zIndex="1" />}
      <Flex
        position="relative"
        h={showVideo ? '345px' : '225px'}
        flex="none"
        alignItems="center"
        direction="column"
        justify="center"
        backgroundColor="#F6F5F1"
      >
        {imageFileName && <Image src={imageFileName} fill style={{ objectFit: 'cover' }} alt="" quality={100} />}
        {!teasing && !hideVideo && (
          <Button variant="ghost" as="a" href={`/video?slug=${slug}`}>
            <Icon zIndex="1" name={showVideo ? 'PlayButton' : 'Play'} color="white" width="112px" height="129px" />
          </Button>
        )}
        {isSoon && (
          <Box py="6px" bgColor="secondary.SECOND" px="10px" position="absolute" right="0" bottom="19px">
            <Text size="3xs" color="white">
              {t('project_status.soon')}
            </Text>
          </Box>
        )}
      </Flex>

      <Flex
        direction="column"
        p={5}
        flex="1 1 0%"
        bgColor="white"
        // {...(isDone && { filter: 'grayscale(100%)' })}
        {...(isBordered && { borderWidth: '0 1px 1px 1px', borderStyle: 'solid', borderColor: '#f6f5f1' })}
      >
        <Heading className="line-clamp2" size="md" height="48px" overflow="hidden" color="text" mb="2">
          {title}
        </Heading>
        <Box mt="2" height="60px">
          <Text as="span" color="text" size="xs">
            {shortDescription}{' '}
          </Text>
          {!teasing && (
            <NextLink href={`/projects/${slug}`} passHref>
              <Text as="span" color="#E87722" size="xs">
                Lire plus
              </Text>
            </NextLink>
          )}
        </Box>
        <Flex align="center" justify="space-between" mt="4">
          <ProjectLocation place={place} />
          {!!voteCollected && (
            <Flex align="center">
              <Icon name="Coin" mr="1" width="16px" height="16px" color="primary.TERTIARY" />
              <Text color="primary.TERTIARY" size="2xs">
                {new Intl.NumberFormat('fr-FR').format(voteCollected * 4)} € reversés
              </Text>
            </Flex>
          )}
        </Flex>
        {showVideo && (
          <Button variant="primary" as="a" href={`/video?slug=${slug}`} alignSelf="center" mt="4">
            regarder la vidéo
          </Button>
        )}
        {!hideVideo && !showVideo && !hideQR && (
          <Flex mt="3" align="center">
            <Icon mr="2" name="LogoMini" width="15px" height="21px" />
            <Text size="lg" color="primary.DEFAULT">
              scannez le QR code, regardez la vidéo et innocent lui reverse 1€
            </Text>
          </Flex>
        )}
      </Flex>
    </Flex>
  )

  return <Card />
}

export default ProjectCard
